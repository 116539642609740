<template>
  <div>
    <!--begin::customer-->
    <div>
          <h3 class="card-label">
            {{ $t('MENU.receipt_document') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div v-if="invoice_code" class="col-lg-12 mb-5">
                <p>{{ $t('receipt_document.purchase_code') }}: {{invoice_code}}</p>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('receipt_document.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.name[0] }}
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('receipt_document.receipt_date') }}<span class="text-danger">*</span></label>
                <input type="date" v-model="data.receipt_date" class="form-control" :class="validation && validation.receipt_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.receipt_date" class="fv-plugins-message-container invalid-feedback">
                {{ validation.receipt_date[0] }}
            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('receipt_document.receipt_number') }}</label>
                <input type="text" v-model="data.receipt_number" class="form-control" :class="validation && validation.receipt_number ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.receipt_number" class="fv-plugins-message-container invalid-feedback">
                {{ validation.receipt_number[0] }}
            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="f_status" v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.status[0] }}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('receipt_document.items') }}</h6>
          </div>
          <div class="bg-white">
            <table class="table table-row-bordered">
              <thead>
              <tr>
                <th>{{ $t('receipt_document.item') }}</th>
                <th>{{ $t('receipt_document.origin_qty') }}</th>
                <th>{{ $t('receipt_document.received_qty') }}</th>
                <!--                    <th></th>-->
              </tr>

              </thead>
              <tbody>
              <template v-if="items_list.length > 0">
                <tr v-for="(row, index) in items_list" :key="index">
                  <td>
                    <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                      <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                      <div class="pl-2 pr-2 text-dark">
                        <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                        <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                      </div>
                    </a>
                  </td>


                  <td><input v-model="row.origin_qty" :disabled="true" type="number" class="form-control"></td>
                  <td><input v-model="row.received_qty" @input="zeroForNegative" type="number" class="form-control"></td>
                  <!--                    <td>-->
                  <!--                      <v-icon style="color: #dc3545;" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>-->
                  <!--                    </td>-->
                </tr>
              </template>
              <tr v-else>
                <td colspan="3">
                  {{ $t('no_items') }}
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('receipt_document.notes') }}</label>
                  <textarea type="text" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                {{ validation.notes[0] }}
            </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>


        <div class="pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6 mt-10">
              <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "form-receipt-document",
  data() {
    return {
      mainRoute: 'purchases/receipt-documents',
      mainInvoiceRoute: 'purchases/purchases-invoice',
      mainRouteDependency: 'base/dependency',
      data: {
        purchase_invoice_id: null,
        name: null,
        receipt_date: null,
        receipt_number: null,
        status: 1,
        notes: null,
      },
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,
      items_list: [],
      status_list: [],
      invoice_code: null,
      // receipt_document_id
      // item_id
      // origin_qty
      // received_qty
    }
  },
  methods:{
    ...timeZoneStructure,
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      ApiService.post(`${this.mainRoute}`, {
        items_list: this.items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'receipt-documents.index'});
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            this.$errorAlert(error);
          });
    },


    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: this.items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'receipt-documents.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    async getData(){
      await ApiService.get(`${this.mainRoute}/${this.idEditing}`).then((response) => {
        this.isEditing = true;
        this.invoice_code = response.data.data.invoice_code;
        this.data.name = response.data.data.name;
        this.data.purchase_invoice_id = response.data.data.purchase_invoice_id;
        this.data.receipt_date = response.data.data.receipt_date;
        this.data.receipt_number = response.data.data.receipt_number;
        this.data.status = response.data.data.status;
        this.data.notes = response.data.data.notes;

        this.items_list = response.data.data.items_list? response.data.data.items_list : [];

      });
    },

    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/8").then((response) => {
        this.data.receipt_number = response.data.data.code;
      });
    },

    async getInvoiceData(){
      await ApiService.get(`${this.mainInvoiceRoute}/${this.$route.params.purchase_invoice_id}`).then((response) => {
        this.items_list = response.data.data? response.data.data : [];
        // this.invoice_code = response.data.data? response.data.data.invoice_code : null;

      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/receipt_document_status").then((response) => {
        this.status_list = response.data.data;
      });
    },

    // zeroForNegative(){
    //   this.items_list = this.items_list.map((row)=>{
    //
    //     if (row.received_qty < 0){
    //       row.received_qty = 0;
    //     }
    //     if (parseInt(row.received_qty) > parseInt(row.origin_qty)){
    //       row.received_qty = parseInt(row.origin_qty);
    //     }
    //     return row;
    //
    //   });
    // },

    zeroForNegative(){
      this.items_list = this.items_list.map((row)=>{

        // if (isNaN(row.received_qty) || !Number(row.received_qty)){
        //   row.received_qty = 0;
        // }else
        if (String(row.received_qty).length > 9) {
          row.received_qty = String(row.received_qty).slice(0, 9);
        }
        if (parseFloat(row.received_qty) < 0){
          row.received_qty = 0;
        }else if (parseFloat(row.received_qty) > parseFloat(row.origin_qty)){
          row.received_qty = (parseFloat(row.origin_qty)).toFixed(3);
        }


        return row;

      });
    },

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchases_invoices"), route: '/purchases/purchases-invoices'},{title: this.$t("MENU.receipt_document")}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    // this.data.receipt_date = new Date().toISOString().substr(0, 10);
    this.getTimeZone().then((res)=>{
      this.data.receipt_date = res;
    });
    this.getStatus();

    if (this.idEditing){
      this.getData();
    }else {

      if (this.$route.params.purchase_invoice_id){
        this.data.purchase_invoice_id = this.$route.params.purchase_invoice_id;
        this.getInvoiceData();
      }
    }


  }
}
</script>

<style scoped>

</style>